import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  colors: {
    primary: '#FF5E50',  // Refined primary coral
    secondary: '#00796B',  // Deeper teal
    background: '#2C2F36',  // Softened dark gray
    text: '#E2E8F0',  // Light text color
  },
  fonts: {
    heading: `'Inter', sans-serif`,  // Clean, modern font for headings
    body: `'Open Sans', sans-serif`,  // Easy-to-read font for body
  },
  styles: {
    global: {
      body: {
        bg: 'background',
        color: 'text',
        fontFamily: 'Arial, sans-serif',
      },
      a: {
        color: 'primary',
        _hover: {
          textDecoration: 'underline',
        },
      },
    },
  },
});

export default theme;
