import { Box, Heading, Text, VStack, Image, SimpleGrid } from '@chakra-ui/react';

const Services = () => {
  return (
    <Box className="site-layout-content" bg="background" color="text" minH="100vh" py={16} px={8}>
      <VStack spacing={12} maxW="900px" mx="auto" textAlign="center">
        <Heading as="h1" size="xl" color="primary">
          Our Services
        </Heading>
        <Text fontSize="lg" maxW="750px">
          Specializing in <strong>custom development, project management, application innovation, and tech consulting</strong>, we create solutions to help your business thrive.
        </Text>
        
        {/* <Image src="/path-to-stock-image.jpg" alt="Professional team working" borderRadius="lg" shadow="xl" mb={8} /> */}

        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="full">
          <Box p={8} borderRadius="lg" shadow="xl" bg="rgba(255, 255, 255, 0.1)">
            <Heading as="h2" size="md" mb={4}>Custom Development</Heading>
            <Text>
              From smart contracts to full-stack applications, we build scalable, secure, and efficient solutions tailored to your needs.
            </Text>
          </Box>

          <Box p={8} borderRadius="lg" shadow="xl" bg="rgba(255, 255, 255, 0.1)">
            <Heading as="h2" size="md" mb={4}>Project Management</Heading>
            <Text>
              We provide expert guidance to keep your project on track, on budget, and optimized for success.
            </Text>
          </Box>

          <Box p={8} borderRadius="lg" shadow="xl" bg="rgba(255, 255, 255, 0.1)">
            <Heading as="h2" size="md" mb={4}>Application Innovation</Heading>
            <Text>
              Enhance existing applications with new technologies, automation, and seamless user experiences.
            </Text>
          </Box>

          <Box p={8} borderRadius="lg" shadow="xl" bg="rgba(255, 255, 255, 0.1)">
            <Heading as="h2" size="md" mb={4}>Tech Consulting</Heading>
            <Text>
              Get expert insights and strategies to optimize your tech stack, ensuring efficiency and scalability for your business.
            </Text>
          </Box>
        </SimpleGrid>
      </VStack>
    </Box>
  );
};

export default Services;
