import React from 'react';
import { Box, Heading, Text, Button, HStack } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import ThreeBackground from '../ThreeBackground';

const Home: React.FC = () => {
  return (
    <Box className="main-content" position="relative" minH="100vh" overflow="hidden">
      {/* Background Animation */}
      <ThreeBackground />

      {/* Full-Screen Overlay */}
      <Box className="overlay" />

      {/* Main Content */}
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        textAlign="center"
        zIndex="1"
      >
        <Heading as="h1" size="2xl" mb={4} color="white">
          Your Software Partner for Innovation
        </Heading>
        <Text fontSize="lg" mb={6} color="white">
          Specializing in custom development, management, and application innovation, we deliver solutions to help your business thrive.
        </Text>
        <HStack spacing={4} justify="center">
          <Button as={Link} to="/contact" colorScheme="teal" size="lg">
            Get Started
          </Button>
          <Button as={Link} to="/services" colorScheme="teal" variant="outline" size="lg">
            Learn More
          </Button>
        </HStack>
      </Box>
    </Box>
  );
};

export default Home;
