import React from 'react';
import { Box, Flex, Heading, Button, Spacer } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Header: React.FC = () => {
  return (
    <Box bg="transparent" color="white" p={4}>
      <Flex as="header" className="App-header" align="center" maxW="1200px" mx="auto" px={4} py={2}>
        <Logo />
        <Heading as="h1" size="lg" flexShrink={0} ml={3} fontSize="24px">
          Vexatek
        </Heading>
        <Spacer />
        <Flex align="center" gap={4} fontSize="md">
          <Button
            as={Link}
            to="/"
            variant="ghost"
            color="white"
            _hover={{ bg: 'white', color: 'primary' }}
          >
            Home
          </Button>
          <Button
            as={Link}
            to="/services"
            variant="ghost"
            color="white"
            _hover={{ bg: 'white', color: 'primary' }}
          >
            Services
          </Button>
          <Button
            as={Link}
            to="/about"
            variant="ghost"
            color="white"
            _hover={{ bg: 'white', color: 'primary' }}
          >
            About
          </Button>
          <Button
            as={Link}
            to="/contact"
            variant="ghost"
            color="white"
            _hover={{ bg: 'white', color: 'primary' }}
          >
            Contact
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
