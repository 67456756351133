import React from 'react';
import { Box } from '@chakra-ui/react';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <Box  color="white" p={4} textAlign="center">
      Vexatek ©{currentYear}
    </Box>
  );
};

export default Footer;
